$(document).ready(function() {

 // || $('.gallery__item.portrait').length > 0)

// Dynamically load the gallery image into a reveal popup
$('.gallery__item a').on('click', function (e) {

		e.preventDefault();

		var $clickedThumbnail = $(this);

		var imagelink = $(this).attr('href');
		var titletext = $(this).data('title');

if  (Cookies.get('hasSeenRotateMessage')) {
		// alert('seen');
		var modal = '<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><button class="gallery-modal__prev">Previous</button><button class="gallery-modal__next">Next</button> <div class="gallery-modal__image row"><div class="image-wrapper columns large-8"><img src="' + imagelink + '"/></div><div class="pop-up-content columns large-4"><span class="caption">' + titletext + '</div></div>';
} else {
		// alert('not seen')
		var modal = '<div class="gallery-rotate"><div class="gallery-rotate__close"></div><div class="gallery-rotate__icon"></div></div><div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><button class="gallery-modal__prev">Previous</button><button class="gallery-modal__next">Next</button> <div class="gallery-modal__image row"><div class="image-wrapper columns large-8"><img src="' + imagelink + '"/></div><div class="pop-up-content columns large-4"><span class="caption">' + titletext + '</div></div>';
}

	Cookies.set('hasSeenRotateMessage', 'true', {expires: 1});






		var $modal = $(modal);

        $('body').append($modal);



        $('.gallery-modal__close').on('click', function () {
	        //$modal.foundation('reveal', 'close');
	        $modal.remove();

        });

				$('.gallery-rotate__close').on('click', function () {
					$('.gallery-rotate').remove();
				});



         $('.gallery-modal__next').on('click', function () {
	        //$modal.foundation('reveal', 'close');
	        $modal.remove();
	        if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
		        $clickedThumbnail.parent().next(':visible').find('a').trigger('click');
		       // $(document).foundation('equalizer', 'reflow');
				$(window).trigger('resize');
				setTimeout(function () {
			    	$(window).trigger('resize');
				}, 500);
	        } else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
		       $clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
		       //$modal.foundation('reveal', 'close');
	        }
        });

        $('.gallery-modal__prev').on('click', function () {
	        //$modal.foundation('reveal', 'close');
	         $modal.remove();
	        if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
		        $clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
		       // $(document).foundation('equalizer', 'reflow');
				$(window).trigger('resize');
				setTimeout(function () {
			    	$(window).trigger('resize');
				}, 500);
	        } else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
		        $clickedThumbnail.parent().prevAll(':visible').first().find('a').trigger('click');
		        //$modal.foundation('reveal', 'close');
	        }
        });
        return false;
	});




	// Filter the gallery items
	var $_filterButtons = $('.gallery__filter-buttons button, .gallery__filter-buttons-mobile button');
	var $_filterItems = $('.gallery__item');

    $_filterButtons.on('click', function() {
	    if ($(this).hasClass('all')) {
			$('.gallery__filter-buttons button.on').removeClass('on');
			$(this).addClass('on');
			$_filterItems.fadeIn();
			//refreshMasonry();
			setTimeout(function () {
		   	 $('.gallery__grid').masonry();
			}, 500);
		    return;
	    }

	    if ($(this).hasClass('on')) {
		    $(this).removeClass('on');
	    } else {
		    $('.gallery__filter-buttons button.on').removeClass('on');
		    $(this).addClass('on');
	    }

        var currentCats = '';

        // Loop through the "on" buttons and build a class selector string of the active
        $_filterButtons.each(function() {
            if ($(this).hasClass('on')) {
                currentCats += '.' + $(this).data('category') + ', ';
            }
        });
        currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks
				// console.log(currentCats);
        // No categories selected so show them all
        if (currentCats == '.undefined') {
			$_filterItems.fadeIn();
				setTimeout(function () {
			   	 $('.gallery__grid').masonry();
				}, 500);
            return;
        }



        // Hide or show the elements that match the class
        $_filterItems.each(function() {
            if ($(this).is(currentCats)) {
                $(this).fadeIn();
            } else {
                $(this).fadeOut();
            }
		});

		setTimeout(function () {
			var count = $('.gallery__item:visible').length;
			//alert ("There are " + count + " divs.");

			if (count > 3) {
			// if (count > 1) {


	      	  //alert("more than 3... specificially " + count);

				$('.gallery__grid').masonry();

	    	}
			else {

				//alert("less than 3... specificially " + count);

				$('.gallery__item:visible').each(function() {
		            $(this).removeAttr("style");
		            $(this).css('float','left');
		            $('.gallery__grid').masonry( 'destroy' );
				});

	    	}


		}, 500);


	});



	// set masonry layout for visible items

	var $grid = $('.gallery__grid').masonry({
		columnWidth: '.gallery__item-sizer',
		// itemSelector: '.gallery__item:not(.gallery__item--hidden)',
		itemSelector: '.gallery__item',
		percentPosition: true,
		horizontalOrder: false
	});

	$( window ).load( function() {
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);
    });


	//layout Masonry after each image loads
	$grid.imagesLoaded().progress( function() {
	  $grid.masonry('layout');
	});


	var refreshMasonry = function() {
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);
	};

	$('#load').click(function() {
		$('.gallery__grid .gallery__item').not(':visible').slice(0, 10).slideDown();
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);

	});


	$('button.all').click(function() {
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);

	});


	$('.gallery__filter-buttons .arrow-down').click(function() {
		$('.gallery__filter-buttons button').show();
		$('.gallery__filter-buttons .arrow-up').show();
		$(this).hide();
	});

	$('.gallery__filter-buttons .arrow-up').click(function() {
		$('.gallery__filter-buttons button').hide();
		$('.gallery__filter-buttons button.on').show();
		$('.gallery__filter-buttons .arrow-down').show();
		$(this).hide();
	});

	// display hidden gallery images
	// $('.gallery__load-more').on('click', function () {
	// 	var btn = $(this);
	// 	var galleryGrid = btn.prev('.gallery__grid');
	// 	var hiddenGalleryItems = galleryGrid.find('.gallery__item--hidden');

		// show next 15 items
		// hiddenGalleryItems.slice(0, 15).fadeIn().promise().done(function () {
		// 	$(this).removeClass('gallery__item--hidden');

			// refresh masonry
		// 	galleryGrid.masonry('reloadItems');
		// 	galleryGrid.masonry('layout');
		// });

		// hide load more button if there is nothing more to load
	// 	if (hiddenGalleryItems.length <= 15) {
	// 		btn.fadeOut();
	// 	}
	// });

	// Pre-filter the offers
	// Get the url parameters
	var urlParams = {};
	var e,
			a = /\+/g, // Regex for replacing addition symbol with a space
			r = /([^&=]+)=?([^&]*)/g,
			d = function(s) {
					return decodeURIComponent(s.replace(a, ' '));
			},
			q = window.location.search.substring(1);

	while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);

	if (urlParams['category']) {
			$('.gallery__filter-buttons button[data-category="' + urlParams['category'] + '"]').trigger('click');
	}

});

$( window ).resize(function() {
	setTimeout(function () {
	    $('.gallery__grid').masonry();
	}, 500);

});
