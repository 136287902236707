$(function () {

    if ($('.finduslocation').length == 0) return false;

        var height = $(window).height();
        $('#LocationsMap').height(height);
        $('.finduslocation .property-selector').css('height', height + 'px');

        var $mapInfo = $('.map-info')
        var map,
        bounds = null
        var markers = []

        var initMap = function () {


            map = new google.maps.Map(document.getElementById('LocationsMap'), {
                zoom: 12,
                center: { lat: parseFloat($mapInfo.attr('data-lat')), lng: parseFloat($mapInfo.attr('data-lng')) },
                disableDefaultUI: true,
                scrollwheel: false,
            });

            /* Window Resize Event */
            google.maps.event.addDomListener(window, 'resize', function () {
                $('#LocationsMap, .location .property-selector, .finduslocation .property-selector').outerHeight($(window).height());
            });

            bounds = new google.maps.LatLngBounds();
            addMarkers($mapInfo);

            var styles = [
              {
                  stylers: [
                    { hue: "#d6dfc8" },
                    { saturation: -20 }
                  ]
              },
              {
                  "featureType": "water",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#d1e5f8"
                      },
                      {
                          "lightness": 17
                      }
                  ]
              },
              {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [
                    { lightness: 100 },
                    { visibility: "simplified" }
                  ]
              },
              {
                  "featureType": "road.highway",
                  "elementType": "geometry.fill",
                  "stylers": [
                      {
                          "color": "#46afd2"
                      },
                      {
                          "lightness": 17
                      }
                  ]
              }
            ];

            map.setOptions({ styles: styles });


        }();

        /* Add Markers Based on Visible Data Attributes */
        function addMarkers($mapInfo) {

            var position = { lat: parseFloat($mapInfo.attr('data-lat')), lng: parseFloat($mapInfo.attr('data-lng')) };

            var marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: new google.maps.MarkerImage($mapInfo.attr('data-marker'), null, null, null, new google.maps.Size(37, 46)),
                pos: position
            });

            markers.push(marker);
            bounds.union(new google.maps.LatLngBounds(new google.maps.LatLng(position.lat, position.lng)));

        }

   
});