$(function () {
    if ($('.brochurelisting').length == 0) return false;

    /* Add Tabs */
    for (var tab in tabs) {
        $('.brochurelisting .tabs').append('<li><a href="#' + tabs[tab] + '">' + tab + '</a></li>');
        //$('.brochurelisting .cab-select-options').append('<li rel="#' + tabs[tab] + '">' + tab + '</li>');
        $('.brochurelisting .dropdown-brochure').append('<option value="#' + tabs[tab] + '">' + tab + '</option>');
    }

    $('.tabs').before('<div class="tab-select"><span>All</span><i class="arrow"><i></i><i></i></i></span>')
    $('.tab-select').click(function () {
        $('.tabs, .tab-select').toggleClass('active');
    });
    $('.tabs li a').click(function () {
        var selectTitle = $(this).text();
        $('.tab-select span').text(selectTitle);
        $('.tabs, .tab-select').removeClass('active')
    });

    //$('.dropdown-brochure').cabSelect();
    //$('.cab-select').prepend('<i class="arrow"><i></i><i></i></i>');
    //$('.career-search input').before('<i class="search-icon"></i>');

    /* Tabs */
    var $tabsContainer = $('.offerslisting .tabs');
    var $tabs = $tabsContainer.find('li');
    var width = 33.33;
    $tabsContainer.css('width', width * $tabs.length + '%').css('margin-left', width + '%');
    $tabs.css('width', (100 / $tabs.length) + '%');

    $('.tabs li a').click(function () {
        $('.tabs li').removeClass('visible');
        $(this).parent().addClass('visible');
    });

    /* Tab Swipe */
    $('.brochurelisting nav').cabSwipe({
        left: function () {
            var $next = $tabsContainer.find('.visible').next();
            if ($next.length > 0) {
                $next.find('a')[0].click();
            }
        },
        right: function () {
            var $prev = $tabsContainer.find('.visible').prev();
            if ($prev.length > 0) {
                $prev.find('a')[0].click();
            }
        }
    });

    /* Apply height of tallest element to all so layout doesn't break */

    var BrochureHeights = $('.categories .brochure').map(function () {
        return $(this).height();
    }).get();

    var maxHeight = Math.max.apply(null, BrochureHeights);

    $('.categories .brochure').height(maxHeight);

    /* Position Tabs */
    function positionTabs() {
        var index = $tabsContainer.find('.visible').index();
        if (index >= 0) {
            $tabsContainer.css('margin-left', -((width * index) - width) + '%');
        }
    }

    /* Handle Hash Change */
    function hashChange() {
        $tabs.removeClass('visible');

        var hash = window.location.hash.replace(/^#/, '');
        if (hash == 'all' || hash == '') {
            $('.brochurelisting .category').addClass('visible');
            $tabs.first().addClass('visible');
        }
        else {
            var $category = $('.brochurelisting .category[data-hash="' + hash + '"]');
            if ($category.length > 0) {
                $('.brochurelisting .category').removeClass('visible');
                $category.addClass('visible');
                $tabs.find('a[href="#' + hash + '"]').closest('li').addClass('visible');
            }
        }
        positionTabs();
    };
    hashChange();
    $(window).bind('hashchange', function () {
        hashChange();
    });
});