$(function () {


$(function () {
	var maxHeight = 0;

	$('.grid-section .container > .row.clearfix .column').each( function() {
		//if ( $(this).height() > maxHeight ) {
		//	maxHeight = $(this).height();
		//}
	});

	//$('.grid-section .container > .row.clearfix .column').height(maxHeight);


});




$(".content-boxes-inner").each( function () {

if ($(window).width() > 760) {

	if ((!$("main").hasClass("restauranthome")) && (!$("main").hasClass("contentdates")) && (!$("body").hasClass("page-1932")) && (!$("body").hasClass("page-20542")) && (!$("body").hasClass("page-1327")) && (!$("body").hasClass("page-1328")) && (!(window.location.href.indexOf("weddings") > -1))) {
		$(".grid-section .container .content-boxes").css({'height':($(".grid-section .container .grid-image").outerHeight() - 50 +'px')});
	} else {
		$('.content-boxes').css('height', '100%');
		$('.grid-image').css('height', '100%');
	}

    $(this).addClass('vertical-center');
	// $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
  }

});



// $('.vertical-center').each( function () {
// $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
// });



(function($){

if ($(window).width() > 960) {

	function equalizeHeights(selector) {
		var heights = new Array();

		// Loop to get all element heights
		$(selector).each(function() {

			// Need to let sizes be whatever they want so no overflow on resize
			$(this).css('min-height', '0');
			$(this).css('max-height', 'none');
			$(this).css('height', 'auto');

			// Then add size (no units) to array
	 		heights.push($(this).height());
		});

		// Find max height of all elements
		var max = Math.max.apply( Math, heights );

		// Set all heights to max height
		$(selector).each(function() {
			$(this).css('height', max + 'px');
		});
	}

	$(window).load(function() {
		// Fix heights on page load
		equalizeHeights('.equalise');

		// Fix heights on window resize
		var iv = null;
		$(window).resize(function() {

			if(iv !== null) {
				window.clearTimeout(iv);
			}

			// Needs to be a timeout function so it doesn't fire every ms of resize
			iv = setTimeout(function() {
	      			equalizeHeights('.equalise');
			}, 120);
		});

	});

	}

})(jQuery);

	/* var equalise = $(".language-landing-half img").height();
	$(".language-landing-half").css('min-height', equalise);
	$(".language-landing-half .vertical-center").css({minHeight:"equalise"});

	$(window).resize(function () {
		var equalise = $(".language-landing-half").height();
		$(".language-landing-half").css('min-height', equalise);
		$(".language-landing-half .vertical-center").css('min-height', equalise);
	}); */


	$('.vertical-center').each( function () {
		$(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
	});


    if ($('.home').length == 0) return false;

    var $gridProperty = $('.grid-property');
    var tabGrid = $gridProperty.data('tabgrid');
    var gridInfoTimer;


    if ($(window).width() < 1099) {
        if ($('.grid-property').length) {
            $('.more-info').each(function () {
                $(this).closest('.grid-property').next('.mobile-more-info').html($(this).html());
                $(this).empty();
            });
        }
    }

    $(window).resize(function () {
        clearTimeout(gridInfoTimer);
        gridTimer = setTimeout(function () {
            gridInfoResize();
        }, 250);
    });

    $('#PropertyQuickList').on('change', function () {
        var loc = $(this).find('option:selected').val();
        if (loc !== '') {
            window.location = $(this).find('option:selected').val();
        }
    });

    $gridProperty.each(function () {
        prependClass($(this).closest('.column'), 'col-tab-' + tabGrid)
    });

    gridInfoResize();


    // FUNCTIONS
    function prependClass(sel, strClass) {
        var $el = jQuery(sel);
        var classes = $el.attr('class');
        classes = strClass + ' ' + classes;
        $el.attr('class', classes);
    };

    function gridInfoResize() {
        var maxHeight = -1;

        if ($(window).width() < 1024 && $(window).width() > 768) {
            $('.mobile-more-info').each(function () {
                maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
            });

            $('.mobile-more-info').each(function () {
                $(this).height(maxHeight);
            });
        }
    };

});
