$(function () {



    if ($('.search').length == 0) return false;

    $(window).on('load', function () {
        $('#SearchResults li').each(function () {
            $(this).equalColumns({
                breakpoints: {
                    0: 1,
                    768: 2
                }
            });
        });
    });

});