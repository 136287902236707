$(function () {

    //if ($('.meetingroom').length == 0) return false;
    var $meetingRoomSlider = $('.meetingroom-slider');
    var $tabsContainer = $meetingRoomSlider.find('.tabs');
    var $tabs = $meetingRoomSlider.find('.tabs li');

    /* Room Plan Tabs */
    var width = 33.33;
    $('.meetingroom-slider').tabbed({
        afterChange: function () {
            $('#ContactDropdown .contacts').height($('#ContactDropdown .slides .visible').height());

            var $tabsContainer = $meetingRoomSlider.find('.tabs');
            var $tab = $tabsContainer.find('.visible');
            if ($(window).width() < 768) {
                $tabsContainer.css('margin-left', -((width * $tab.index()) - width) + '%');
            }
        }
    });

    if ($(window).width() < 768) {
        $meetingRoomSlider.children('.tabs').css('width', width * $tabs.length + '%').css('margin-left', width + '%');
        $tabs.css('width', (100 / $tabs.length) + '%');
    }


    setTimeout(function () {
        var slideHeight = $meetingRoomSlider.find('.slides img').height();
        $meetingRoomSlider.find('.slides').height(slideHeight);
    }, 2000);


    /* Room Plan Swipe */
    $meetingRoomSlider.cabSwipe({
        left: function () {
            $meetingRoomSlider.trigger('next');
        },
        right: function () {
            $meetingRoomSlider.trigger('prev');
        }
    });


    /* Full Screen Meeting Room Popup */

    var $container = $('.meeting-carousel');
    //var $inline = $('.inline-carousel');
    //var $full = $('.full-carousel');

    //if ($inline.length == 0 || $full.length == 0) return false;

    $('.meeting-layouts .expand-carousel, .meeting-layouts .contract-carousel').click(function () {
        if (!$container.hasClass('fixed')) {
            var scroll = (($('.meetingroom-slider ul').offset().top - ($(window).height() / 2)) + ($container.height() / 2));
            $('html, body').animate({ scrollTop: scroll }, 400, function () {
                $container.addClass('fixed');
            });
            $('#siteWrap').addClass('lock');
        }
        else {
            $container.removeClass('fixed');
            $('#siteWrap').removeClass('lock');
        }
    });


    $container.children('.inner').slick({
        dots: true,
        autoplay: false,
        touchThreshold: 25,
        arrows: false,
        centerMode: true,
        centerPadding: '20%',
        initialSlide: $('.meetingroom-slider .slides .visible').index() + 1
    });







    $('.date-picker').datepicker({
        defaultDate: null,
        dateFormat: "dd-mm-yy",
        //-dayNamesMin: ["M", "T", "W", "T", "F", "S", "S"],
        minDate: 0
    });

    if ($('.eventslisting').length) {
        $('body').addClass('events-listing');
    }

    if ($('.eventssearchresults').length) {
        $('body').addClass('events-eventssearchresults');
        $('.events-eventssearchresults .eventssearchresults button').addClass('animated-button dark')
    }
    
    
    

		$('#meetingsAccordion').accordiom({			
			showFirstItem: false,
			autoClosing: true			
		});


});