$(function () {
    var $buttons = $('.add-to-me');
    var $meForm = $buttons.parent('form')
    if ($buttons.length == 0) return false;

    $buttons.append('<svg viewBox="0 0 29 27.5"><path d="M14.5,4.7c-1.4-6-13.9-6.3-14,3.9c0,10.2,14,18.2,14,18.2s0,0,0,0 c0,0,14-8,14-18.2C28.5-1.6,15.9-1.3,14.5,4.7C14.5,4.7,14.5,4.8,14.5,4.7z" /></svg>');

    $meForm.on('submit', function (e) {

        e.preventDefault();

        var form      = $(this);
        var post_url  = form.attr('action');
        var post_data = form.serialize();

        $.ajax({
            type: 'POST',
            url: post_url,
            data: post_data,
            dataType: 'json',
            success: function (data) {  
                form.find('.add-to-me').addClass('added');          
            },
            error: function () {

            }
        });
    });

});