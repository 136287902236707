var players = {};
var YTAPIReady = false;

function onYouTubeIframeAPIReady() {
    YTAPIReady = true;
}

function onPlayerReady(event) {
    var mobileDevice = false;
    if (typeof window.orientation !== 'undefined') {
        var mobileDevice = true;
    }

    if (mobileDevice == false) {
        event.target.playVideo();
    }
}

function playYTVideo(videoID) {
    if (players[videoID] == null) {
        if (YTAPIReady == true) {
            var player = new YT.Player('player-' + videoID, {
                height: '100%',
                width: '100%',
                videoId: videoID,
                playerVars: {
                    controls: 1,
                    disablekb: 1
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }

            });
            players[videoID] = player;
        }
        else {
            console.log("Error with YT API");
        }
    } else {
        players[videoID].loadVideoById(videoID);
        $('#player-' + videoID).show();
    }
}

function pauseYTVideo(videoID) {
    players[videoID].pauseVideo();
}

function onPlayerStateChange(event) {
    if (event.data === 0) {
        hideVideo(event.target.getIframe());
    }
}

function hideVideo(video) {
    $(video).hide();
    var $videoWrap = $(video).closest('.grid-video');
    $videoWrap.find('.play-btn').fadeIn();
}

// Retain 16:9 aspect ratio with YT video
var resizeTimer;
function iframeResize() {
    //$('.col-tab-12 .videoWrapper, .col-tab-12 .grid-video').each(function () {
    //    vidWidth = $(this).width();
    //    var vidHeight = (vidWidth / 16) * 9;
    //    $(this).height(vidHeight);
    //});
};

$(window).resize(function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(iframeResize, 250);
});

$(function () {

    iframeResize();

    $('.grid-video .play-btn').on('click', function () {
        playYTVideo($(this).attr('data-id'));
        $(this).fadeOut();
        $(this).closest('.grid-video').addClass('playing');
    });

});