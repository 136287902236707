$(document).ready(function () {
    $(".search-header input[type='text']").keyup(function () {
        var searchTerm = $(".search-header input").val();
        if (searchTerm.length > 2) {
            $.getJSON('/umbraco/api/ExclusiveSearch/GetMatchingTerms/', { SearchTerm: searchTerm, Max: 5, Category: "search" })
                .done(function (data) {
                    $('#Results').empty();

                    // We have the data - show it
                    $.each(data, function (key, item) {
                        $('<li>', { text: item }).appendTo($('#Results'));
                    });

                    $('#Results').on('click', 'li', function () {
                        $(".search-header input").val($(this).text())
                        $('#Results').empty();
                    });
                });
        }
    });



    $("#headerSearch input[type='text']").keyup(function () {
        var searchTerm = $("#headerSearch input").val();
        if (searchTerm.length > 2) {
            $.getJSON('/umbraco/api/ExclusiveSearch/GetMatchingTerms/', { SearchTerm: searchTerm, Max: 5, Category: "search" })
                .done(function (data) {

                    $('#TopResults').empty();

                    // We have the data - show it
                    $.each(data, function (key, item) {
                        $('<li>', { text: item }).appendTo($('#TopResults'));
                    });

                    $('#Results').on('click', 'li', function () {
                        $("#headerSearch input").val($(this).text())
                        $('#TopResults').empty();
                    });
                });
        }
    });





    $(document).on("click", '#TopResults li', function (event) {
        var resultData = $(this).html()
        $('#headerSearch input[type=text]').val(resultData)
        $('#headerSearch').submit();
    });

});