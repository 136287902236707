$(function() {

    initAnimateInView();
    initMaps();
    
    function initAnimateInView() {
        $(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
        animateElementsInView();
    }

    function animateElementsInView() {
        $('[data-animate-in-view]').each(function () {
            if ( $(this).hasClass('in-view') ) {
                return; 
            }
            
            if ( isElementInViewport($(this)) ) {
                var elementToAnimate = this;
                
                $(elementToAnimate).addClass('in-view'); // log that this element has been seen
                
                
                setTimeout(function () {
                    $(elementToAnimate).addClass( $(elementToAnimate).data('animate-in-view') ); // apply the animation class
                }, 300);
                
                
                if ( typeof $(elementToAnimate).data('map-load-on-view') != 'undefined' ) { // only load this map once it's in view
                    buildMap(elementToAnimate);
                }
            }
        });
    }

    function isElementInViewport (el) {
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }
        
        var rect = el.getBoundingClientRect();
        
        var t = rect.y;
        var b = window.innerHeight - rect.y - rect.height;
        var h = window.innerHeight;
        
        if (
            ( (t >= 0) && (t <= h) ) ||
            ( (b >= 0) && (b <= h) )
        ) {
            return true;
        } else {
            return false;
        }
    }



    function initMaps() {
        $('#map').each(function () {
            buildMap(this);
        });
    }


    function buildMap(mapElement) {


        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('map');

        if (mapElement !== null) {
            // When the window has finished loading create our google map below
            google.maps.event.addDomListener(window, 'load', init);

            function init() {
                // Basic options for a simple Google Map
                // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
                var mapOptions = {
                    // How zoomed in you want the map to start at (always required)
                    zoom: 8,
                    mapTypeControl: false,
                    fullscreenControl: false,

                    // The latitude and longitude to center the map (always required)
                    center: new google.maps.LatLng(51.285436, -1.344206), // central point for all properties

                    // How you would like to style the map.
                    // This is where you would paste any style found on Snazzy Maps.
                    styles: [
                        { featureType: 'all', elementType: 'geometry.fill', stylers: [{ weight: '2.00' }] },
                        { featureType: 'all', elementType: 'geometry.stroke', stylers: [{ color: '#9c9c9c' }] },
                        { featureType: 'all', elementType: 'labels.text', stylers: [{ visibility: 'on' }] },
                        { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f2f2f2' }] },
                        { featureType: 'landscape', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }] },
                        { featureType: 'landscape.man_made', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }] },
                        { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
                        { featureType: 'road', elementType: 'all', stylers: [{ saturation: -100 }, { lightness: 45 }] },
                        { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: '#eeeeee' }] },
                        { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#7b7b7b' }] },
                        { featureType: 'road', elementType: 'labels.text.stroke', stylers: [{ color: '#ffffff' }] },
                        { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'simplified' }] },
                        { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
                        { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] },
                        { featureType: 'water', elementType: 'all', stylers: [{ color: '#b0bdc8' }, { visibility: 'on' }] },
                        { featureType: 'water', elementType: 'geometry.fill', stylers: [{ color: '#b0bdc8' }] },
                        { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#070707' }] },
                        { featureType: 'water', elementType: 'labels.text.stroke', stylers: [{ color: '#ffffff' }] }
                    ]
                };

                // Create the Google Map using our element and options defined above
                var map = new google.maps.Map(mapElement, mapOptions);

                if ($(window).width() < 600) {
                    map.setZoom(6);
                }

                // markers
                var markers = [];

                $('#properties div[data-marker]').each(function(i, el) {
                    var $el = $(el);

                    var marker = new google.maps.Marker({
                        position: new google.maps.LatLng($el.data('lat'), $el.data('lng')),
                        map: map,
                        title: $el.data('name'),
                        icon: {
                            url: $el.data('marker')
                        },
                        panel: '#' + $el.attr('id'),
                        categories: $el.data('categories').split(',')
                    });

                    markers.push(marker);
                });

                // panels

                markers.forEach(function(marker) {
                    if (marker.categories.contains('hide-pin')) {
                        marker.setVisible(false);
                    }

                    marker.addListener('click', function() {
                        if (marker.categories.contains('double-pin')) {
                            map.panTo(marker.getPosition());
                            map.setZoom(14);
                            marker.setVisible(false);

                            markers.forEach(function(marker) {
                                if (marker.categories.contains('hide-pin')) {
                                    marker.setVisible(true);

                                    if ($(window).width() > 600) {
                                        $('#the-manor-house').show();
                                    }

                                    $('#map').css({ height: $(marker.panel).height() + 'px' });

                                    marker.addListener('click', function() {
                                        //alert ('hi');
                                        map.panTo(marker.getPosition());
                                        map.setZoom(20);
                                        $('.panel').hide();
                                        $(marker.panel).show();
                                        $('#map').css({ height: $(marker.panel).height() + 'px' });
                                    });
                                }
                            });
                        } else if (marker.categories.contains('hide-pin')) {
                            if ($(window).width() < 600) {
                                $('html,body').animate(
                                    {
                                        scrollTop: $('#properties').offset().top - 67
                                    },
                                    'slow'
                                );
                            }
                        } else {
                            $('.panel').hide();
                            $(marker.panel).show();
                            $('#map').css({ height: $(marker.panel).height() + 'px' });
                            map.panTo(marker.getPosition());
                            map.setZoom(12);

                            if ($(window).width() < 600) {
                                $('html,body').animate(
                                    {
                                        scrollTop: $('#properties').offset().top - 67
                                    },
                                    'slow'
                                );
                            }
                        }
                    });
                });

                $('.panel .close').click(function(event) {

                    event.preventDefault();

    	             	$('.contact-1').show();
                        $('.contact-2').hide();
                        $('.contact-2-link').removeClass('selected');
                        $('.contact-1-link').addClass('selected'); 
                        
                        
                       
                                           
                        
                     /*   $('.restaurant-1').hide();
    					$('.restaurant-2').hide();
    					$('.restaurant-3').hide();
    					$('.restaurant-4').hide();
                        $('.restaurant-1-link').removeClass('selected');
    					$('.restaurant-2-link').removeClass('selected');
    					$('.restaurant-3-link').removeClass('selected');
    					$('.restaurant-4-link').addClass('selected'); */
    					
    					
                    if ($('#map').css('display') == 'none') {
                        $('.panel').hide();
                        $('#map').hide();
                        $('#mobile-list').show();
                        $('main').css({ height: 'auto' });
                        
    					
                    } else {
                        markers.forEach(function(marker) {
    	                    
                            if (marker.categories.contains('hide-pin')) {
                                marker.setVisible(false);
                            } 
                            /* if (marker.categories.contains('double-pin')) {
    							marker.setVisible(true);
    						} */
                        });

                        $('.panel').hide();
                        map.setZoom(8);

                        if ($(window).width() < 600) {
                            $('html,body').animate(
                                {
                                    scrollTop: $('.siteWrap').offset().top
                                },
                                'slow'
                            );
                        }
                    }
                });

                $('.panel .contact-1-link').click(function() {
                    $('.contact-1').show();
                    $('.contact-2').hide();
                    $('.contact-2-link').removeClass('selected');
                    $('.contact-1-link').addClass('selected');
                    $('#map').css({
                        height:
                            $(this)
                                .closest('.panel')
                                .height() + 'px'
                    });
                    if ($(window).width() < 600) {
                        $('html,body').animate(
                            {
                                scrollTop: $('#properties').offset().top - 67
                            },
                            'slow'
                        );
                    }
                });

                $('.panel .contact-2-link').click(function() {
                    $('.contact-2').show();
                    $('.contact-1').hide();
                    $('.contact-1-link').removeClass('selected');
                    $('.contact-2-link').addClass('selected');
                    $('#map').css({
                        height:
                            $(this)
                                .closest('.panel')
                                .height() + 'px'
                    });
                    if ($(window).width() < 600) {
                        $('html,body').animate(
                            {
                                scrollTop: $('#properties').offset().top - 67
                            },
                            'slow'
                        );
                    }
                });
                
                
                $('.panel .restaurant-1-link').click(function() {
                    $('.restaurant-1').show();
                    $('.restaurant-2').hide();
                    $('.restaurant-3').hide();
                    $('.restaurant-4').hide();
                    $('.restaurant-2-link').removeClass('selected');
                    $('.restaurant-3-link').removeClass('selected');
                    $('.restaurant-4-link').removeClass('selected');
                    $('.restaurant-1-link').addClass('selected');
                    $('#map').css({
                        height:
                            $(this)
                                .closest('.panel')
                                .height() + 'px'
                    });
                    if ($(window).width() < 600) {
                        $('html,body').animate(
                            {
                                scrollTop: $('#properties').offset().top - 67
                            },
                            'slow'
                        );
                    }
                });

                $('.panel .restaurant-2-link').click(function() {
                    $('.restaurant-2').show();
                    $('.restaurant-1').hide();
                    $('.restaurant-3').hide();
                    $('.restaurant-4').hide();
                    $('.restaurant-1-link').removeClass('selected');
                    $('.restaurant-3-link').removeClass('selected');
                    $('.restaurant-4-link').removeClass('selected');
                    $('.restaurant-2-link').addClass('selected');
                    $('#map').css({
                        height:
                            $(this)
                                .closest('.panel')
                                .height() + 'px'
                    });
                    if ($(window).width() < 600) {
                        $('html,body').animate(
                            {
                                scrollTop: $('#properties').offset().top - 67
                            },
                            'slow'
                        );
                    }
                });
                
                 $('.panel .restaurant-3-link').click(function() {
                    $('.restaurant-3').show();
                    $('.restaurant-1').hide();
                    $('.restaurant-2').hide();
                    $('.restaurant-4').hide();
                    $('.restaurant-1-link').removeClass('selected');
                    $('.restaurant-2-link').removeClass('selected');
                    $('.restaurant-4-link').removeClass('selected');
                    $('.restaurant-3-link').addClass('selected');
                    $('#map').css({
                        height:
                            $(this)
                                .closest('.panel')
                                .height() + 'px'
                    });
                    if ($(window).width() < 600) {
                        $('html,body').animate(
                            {
                                scrollTop: $('#properties').offset().top - 67
                            },
                            'slow'
                        );
                    }
                });
                
                 $('.panel .restaurant-4-link').click(function() {
                    $('.restaurant-4').show();
                    $('.restaurant-1').hide();
                    $('.restaurant-2').hide();
                    $('.restaurant-3').hide();
                    $('.restaurant-1-link').removeClass('selected');
                    $('.restaurant-2-link').removeClass('selected');
                    $('.restaurant-3-link').removeClass('selected');
                    $('.restaurant-4-link').addClass('selected');
                    $('#map').css({
                        height:
                            $(this)
                                .closest('.panel')
                                .height() + 'px'
                    });
                    if ($(window).width() < 600) {
                        $('html,body').animate(
                            {
                                scrollTop: $('#properties').offset().top - 67
                            },
                            'slow'
                        );
                    }
                });

                // Filters
                
               

                $('.spa-filter').click(function() {
                    $('.filter').removeClass('filter--active');
                    $(this).addClass('filter--active');

                    $('.panel').removeClass('restaurant');
                    $('.panel').hide();
                    map.setZoom(8);

                    markers.forEach(function(marker) {
                        if (marker.categories.contains('spa')) {
                            marker.setVisible(true);
                            $('.contact-2-link').trigger('click');
                        } else {
                            marker.setVisible(false);
                        }
                    });
                });
                
                
                $('.panel .close').click(function(event) {
                    
                    event.preventDefault();
    	            
    	            if ( $('.spa-filter').hasClass( "filter--active" ) ) {
    		            
    		            //alert('spa');
     
    			        $('.panel').removeClass('restaurant');
    					$('.panel').hide();
    					map.setZoom(8);
    					
    					
    					markers.forEach(function(marker) {
                        if (marker.categories.contains('spa')) {
                            marker.setVisible(true);
                            $('.contact-2-link').trigger('click');
    	                    } else {
    	                        marker.setVisible(false);
    	                    }
                    	});

    			 
    			    } 
    			    
    			    


    	             
    	         });
                 
                 

                $('.golf-filter').click(function() {
                    $('.filter').removeClass('filter--active');
                    $(this).addClass('filter--active');

                    $('.panel').removeClass('restaurant');
                    $('#the-manor-house .close').addClass('filter-result');
                    $('.panel').hide();
                    map.setZoom(8);

                    markers.forEach(function(marker) {
                        if (marker.categories.contains('golf')) {
                            marker.setVisible(true);
                            marker.addListener('click', function() {
                                map.panTo(marker.getPosition());
                                map.setZoom(12);
                                $('.panel').hide();
                                $(marker.panel).show();
                                $('#map').css({ height: $(marker.panel).height() + 'px' });
                            });
                           // $('.contact-2-link').trigger('click');
                           $('.contact-1').show();
                        } else {
                            marker.setVisible(false);
                        }
                    });
                });

                $('.properties-filter').click(function() {
                    $('.filter').removeClass('filter--active');
                    $(this).addClass('filter--active');

                    /* if (marker.categories.contains('double-pin')) {
    				marker.setVisible(true);
    				}	*/

                    $('.contact-1').show();
                    $('.contact-2').hide();
                    $('.contact-2-link').removeClass('selected');
                    $('.contact-1-link').addClass('selected');

                    $('.panel').removeClass('restaurant');
                    $('.panel').hide();
                    map.setZoom(8);

                    markers.forEach(function(marker) {
                        if (marker.categories.contains('properties')) {
                            marker.setVisible(true);
                            $('.contact-1-link').trigger('click');
                        } else {
                            marker.setVisible(false);
                        }
                    });

                    markers.forEach(function(marker) {
                        if (marker.categories.contains('hide-pin')) {
                            marker.setVisible(false);
                        }
                        if (marker.categories.contains('double-pin')) {
                            marker.setVisible(true);
                        }
                    });
                });

                $('.restaurant-filter').click(function() {
                    $('.filter').removeClass('filter--active');
                    $(this).addClass('filter--active');

                    $('.panel').addClass('restaurant');
                    $('.panel').hide();
                    map.setZoom(8);

                    markers.forEach(function(marker) {
                        if (marker.categories.contains('restaurant')) {
                            marker.setVisible(true);
                        } else {
                            marker.setVisible(false);
                        }

                        if (marker.categories.contains('hide-pin')) {
                            marker.setVisible(false);
                        }
                        if (marker.categories.contains('double-pin')) {
                            marker.setVisible(true);
                        }
                    });
                });

                $('.cookery-filter').click(function() {
                    $('.filter').removeClass('filter--active');
                    $(this).addClass('filter--active');

                    $('.panel').removeClass('restaurant');
                    $('.panel').hide();
                    map.setZoom(8);

                    markers.forEach(function(marker) {
                        if (marker.categories.contains('cookery')) {
                            marker.setVisible(true);
                            $('.contact-2-link').trigger('click');
                        } else {
                            marker.setVisible(false);
                        }
                    });
                });

                $('.pub-filter').click(function() {
                    $('.filter').removeClass('filter--active');
                    $(this).addClass('filter--active');

                    $('.panel').removeClass('restaurant');
                    $('#the-castle-inn .close').addClass('filter-result');
                    $('.panel').hide();
                    
                    if ($(window).width() < 600) {
                        map.setZoom(7);
                    } else {
    	                map.setZoom(8);
                    }
                    

                    markers.forEach(function(marker) {
                        if (marker.categories.contains('pub')) {
                            marker.setVisible(true);
                            marker.addListener('click', function() {
                                $('.panel').hide();
                                $(marker.panel).show();
                                $('#map').css({ height: $(marker.panel).height() + 'px' });
                                $('.contact-1-link').trigger('click');
                            });
                        } else {
                            marker.setVisible(false);
                        }
                    });
                });

                $('#the-manor-house .close').click(function() {
                    if ($(this).hasClass('filter-result')) {
                        markers.forEach(function(marker) {
                            if (marker.categories.contains('golf')) {
                                marker.setVisible(true);
                            } 
                        });

                        markers.forEach(function(marker) {
                            if (marker.categories.contains('double-pin')) {
                                marker.setVisible(false);
                            }
                        });
                    } else {
                        markers.forEach(function(marker) {
                            if (marker.categories.contains('golf')) {
                                marker.setVisible(false);
                            }
                        });

                        markers.forEach(function(marker) {
                            if (marker.categories.contains('double-pin')) {
                                marker.setVisible(true);
                            }
                        });
                    }
                });

                $('#the-castle-inn .close').click(function() {
                    if ($(this).hasClass('filter-result')) {
                        markers.forEach(function(marker) {
                            if (marker.categories.contains('pub')) {
                                marker.setVisible(true);
                            }
                        });

                        markers.forEach(function(marker) {
                            if (marker.categories.contains('double-pin')) {
                                marker.setVisible(false);
                            }
                        });
                    } else {
                        markers.forEach(function(marker) {
                            if (marker.categories.contains('pub')) {
                                marker.setVisible(false);
                            }
                        });

                        markers.forEach(function(marker) {
                            if (marker.categories.contains('double-pin')) {
                                marker.setVisible(true);
                            }
                        });
                    }
                });

    			$('#the-manor-house-golf-club .close').click(function() {
                    if ($(this).hasClass('filter-result')) {
                        markers.forEach(function(marker) {
                            if (marker.categories.contains('golf')) {
                                marker.setVisible(true);
                            }
                        });

                        markers.forEach(function(marker) {
                            if (marker.categories.contains('double-pin')) {
                                marker.setVisible(false);
                            }
                        });
                    } else {
                        markers.forEach(function(marker) {
                            if (marker.categories.contains('golf')) {
                                marker.setVisible(false);
                            }
                        });

                        markers.forEach(function(marker) {
                            if (marker.categories.contains('double-pin')) {
                                marker.setVisible(true);
                            }
                        });
                    }
                });


                // mobile

                $('.filters a').click(function() {
                    if ($(window).width() < 600) {
                        $('#map').show();
                    }
                });

                $('.map-view').click(function() {
                    $('#mobile-list').hide();
                    $('#map').show();
                    $('.filter').show();
                    $('.list-view').removeClass('selected');
                    $('.map-view').addClass('selected');
                });

                $('.list-view').click(function() {
                    $('#mobile-list').show();
                    $('.filter').hide();
                    $('#map').hide();
                    $('.map-view').removeClass('selected');
                    $('.list-view').addClass('selected');
                });

                $('.mobile-filter-title').click(function() {
                    $('.filters').toggle();
                });

                $('.panel .mobile-close').click(function() {
                    $('.panel').hide();
                    $('#map').hide();
                    $('#mobile-list').show();
                    $('main').css({ height: 'auto' });
                });

                $('#mobile-list a').click(function() {
    			var id = $(this).attr('href');
    			$('.panel').hide();	
    			$(id).show();
    			$("main").css({ 'height': ($(id).outerHeight() + 185) + 'px' });
    			
    			$('html,body').animate({
    					scrollTop: $("#properties").offset().top - 67
    				}, 'slow');

    			return false;
    			}); 

                /* */

                // Pre-filter the hotel location links
                // Get the url parameters
                var urlParams = {};
                var e,
                    a = /\+/g, // Regex for replacing addition symbol with a space
                    r = /([^&=]+)=?([^&]*)/g,
                    d = function(s) {
                        return decodeURIComponent(s.replace(a, ' '));
                    },
                    q = window.location.search.substring(1);

                while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);

                markers.forEach(function(marker) {
                    if (urlParams['property'] === marker.panel.substring(1)) {
                        google.maps.event.trigger(marker, 'click');
                        return true;
                    }
                });
                /* */
            }

            Array.prototype.contains = function(obj) {
                var i = this.length;
                while (i--) {
                    if (this[i] === obj) {
                        return true;
                    }
                }
                return false;
            };
        }
    }
});
