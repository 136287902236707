$(function () {

    //if ($('.roomlisting').length == 0) return false;

    $('.pillow-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: 0,
        touchThreshold: 20,
        arrows: true,
        focusOnSelect: true,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            }
        ]
    });


    $('.pillow-carousel').find('.slick-prev').append('<i class="icon-arrow-left"></i>');
    $('.pillow-carousel').find('.slick-next').append('<i class="icon-arrow-right"></i>');


});