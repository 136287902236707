$(function () {

    initBookingBar();
    changeBookingProperty();

    // init accordions
    $('.sr__split-intro-features-mobile').accordiom({
        showFirstItem: false
    })

    $('.sr__offers-landing-filter-location').accordiom({
        showFirstItem: false
    })

    $('.sr__offers-landing-filter-category').accordiom({
        showFirstItem: false
    })

    $('.sr__experiences-landing-filter-mobile').accordiom({
        showFirstItem: false
    })

    $('.gallery__property-list-dropdown').accordiom({
        showFirstItem: false
    })

    $('.gallery__filter-buttons-mobile-accordion').accordiom({
        showFirstItem: false
    })

    $('.sr__booking-mobile-accordion').accordiom({
        showFirstItem: false
    })


    // init sliders
    $('.sr__room-carousel-slider').slick({
        centerMode: true,
        centerPadding: '200px',
        slidesToScroll: 1,
        slidesToShow:1,
        dots: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    centerpadding: '0px'
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    centerMode: false
                }
            }
        ]
    })

    //init booking bar
    $('.sr__booking-form-type select, .sr__booking-mobile-form-type select').change(function() {
        // Hide all
        $('.sr__booking-form-property, .sr__booking-mobile-form-property').find('.room-select, .table-select, .tea-select, .treatment-select, .class-select, .time-select, .lunch-select').css('display', 'none');

        $('.sr__booking-form-room-booking, .sr__booking-form-table-booking, .sr__booking-form-tea-booking, .sr__booking-form-treatment-booking, .sr__booking-form-class-booking, .sr__booking-form-class-booking, .sr__booking-form-time-booking, .sr__booking-form-lunch-booking').css('display', 'none');
        $('.sr__booking-mobile-form-room-booking, .sr__booking-mobile-form-table-booking, .sr__booking-mobile-form-tea-booking, .sr__booking-mobile-form-treatment-booking, .sr__booking-mobile-form-class-booking, .sr__booking-mobile-form-class-booking, .sr__booking-mobile-form-time-booking, .sr__booking-mobile-form-lunch-booking').css('display', 'none');

        // Show relevant desktop
        $('.sr__booking-form-property .' + $(this).val().toLowerCase() + '-select').css('display', 'inline-block');
        $('.sr__booking-form-' + $(this).val().toLowerCase() + '-booking').css('display', 'inline-block');

        // Show relevant mobile
        $('.sr__booking-mobile-form-property .' + $(this).val().toLowerCase() + '-select').css('display', 'inline-block');
        $('.sr__booking-mobile-form-' + $(this).val().toLowerCase() + '-booking').css('display', 'inline-block');


        initBookingBar();
    })

    var propertySelected = '';
    var tableSelected = '';
    var spaSelected = '';

    if ( typeof $('.sr__booking-form-property .room-select select, .sr__booking-mobile-form-property .room-select select').val() !== 'undefined' ) {
        propertySelected = $('.sr__booking-form-property .room-select select, .sr__booking-mobile-form-property .room-select select').val().toLowerCase();
    }
    if ( typeof $('.sr__booking-form-property .table-select select, .sr__booking-mobile-form-property .table-select select').val() !== 'undefined' ) {
        tableSelected = $('.sr__booking-form-property .table-select select, .sr__booking-mobile-form-property .table-select select').val().toLowerCase();
    }
    if ( typeof $('.sr__booking-form-property .treatment-select select, .sr__booking-mobile-form-property .treatment-select select').val() !== 'undefined' ) {
        spaSelected = $('.sr__booking-form-property .treatment-select select, .sr__booking-mobile-form-property .treatment-select select').val().toLowerCase();
    }


    if ( propertySelected ) {
        if( propertySelected == 'pennyhill-park-room' ) {
            $('.sr__booking-form-room-booking .pennyhill-park-room').css('display', 'block');
            $('.sr__booking-form-room-booking .pennyhill-park-room').addClass('active');
            $('.sr__booking-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-form-room-booking .south-lodge-room').removeClass('active');
            $('.sr__booking-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .lainston-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-manor-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-form-room-booking .royal-berkshire-room').removeClass('active');
            $('.sr__booking-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-form-room-booking .fanhams-hall-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-castle-inn-room').removeClass('active');

            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').css('display', 'block');
            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').addClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').removeClass('mobile-active');
            } else if( propertySelected == 'south-lodge-room' ) {
            $('.sr__booking-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-form-room-booking .pennyhill-park-room').removeClass('active');
            $('.sr__booking-form-room-booking .south-lodge-room').css('display', 'block');
            $('.sr__booking-form-room-booking .south-lodge-room').addClass('active');
            $('.sr__booking-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .lainston-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-manor-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-form-room-booking .royal-berkshire-room').removeClass('active');
            $('.sr__booking-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-form-room-booking .fanhams-hall-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-castle-inn-room').removeClass('active');

            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').css('display', 'block');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').addClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').removeClass('mobile-active');
            } else if( propertySelected == 'lainston-house-room' ) {
            $('.sr__booking-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-form-room-booking .pennyhill-park-room').removeClass('active');
            $('.sr__booking-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-form-room-booking .south-lodge-room').removeClass('active');
            $('.sr__booking-form-room-booking .lainston-house-room').css('display', 'block');
            $('.sr__booking-form-room-booking .lainston-house-room').addClass('active');
            $('.sr__booking-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-manor-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-form-room-booking .royal-berkshire-room').removeClass('active');
            $('.sr__booking-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-form-room-booking .fanhams-hall-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-castle-inn-room').removeClass('active');

            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').css('display', 'block');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').addClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').removeClass('mobile-active');
            } else if( propertySelected == 'the-manor-house-room' ) {
            $('.sr__booking-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-form-room-booking .pennyhill-park-room').removeClass('active');
            $('.sr__booking-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-form-room-booking .south-lodge-room').removeClass('active');
            $('.sr__booking-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .lainston-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-manor-house-room').css('display', 'block');
            $('.sr__booking-form-room-booking .the-manor-house-room').addClass('active');
            $('.sr__booking-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-form-room-booking .royal-berkshire-room').removeClass('active');
            $('.sr__booking-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-form-room-booking .fanhams-hall-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-castle-inn-room').removeClass('active');

            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').css('display', 'block');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').addClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').removeClass('mobile-active');
            } else if( propertySelected == 'royal-berkshire-room' ) {
            $('.sr__booking-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-form-room-booking .pennyhill-park-room').removeClass('active');
            $('.sr__booking-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-form-room-booking .south-lodge-room').removeClass('active');
            $('.sr__booking-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .lainston-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-manor-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .royal-berkshire-room').css('display', 'block');
            $('.sr__booking-form-room-booking .royal-berkshire-room').addClass('active');
            $('.sr__booking-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-form-room-booking .fanhams-hall-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-castle-inn-room').removeClass('active');

            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').css('display', 'block');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').addClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').removeClass('mobile-active');
            } else if( propertySelected == 'fanhams-hall-room' ) {
            $('.sr__booking-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-form-room-booking .pennyhill-park-room').removeClass('active');
            $('.sr__booking-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-form-room-booking .south-lodge-room').removeClass('active');
            $('.sr__booking-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .lainston-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-manor-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-form-room-booking .royal-berkshire-room').removeClass('active');
            $('.sr__booking-form-room-booking .fanhams-hall-room').css('display', 'block');
            $('.sr__booking-form-room-booking .fanhams-hall-room').addClass('active');
            $('.sr__booking-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-castle-inn-room').removeClass('active');

            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').css('display', 'block');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').addClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').removeClass('mobile-active');
            } else if( propertySelected == 'the-castle-inn-room' ) {
            $('.sr__booking-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-form-room-booking .pennyhill-park-room').removeClass('active');
            $('.sr__booking-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-form-room-booking .south-lodge-room').removeClass('active');
            $('.sr__booking-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .lainston-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-form-room-booking .the-manor-house-room').removeClass('active');
            $('.sr__booking-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-form-room-booking .royal-berkshire-room').removeClass('active');
            $('.sr__booking-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-form-room-booking .fanhams-hall-room').removeClass('active');
            $('.sr__booking-form-room-booking .the-castle-inn-room').css('display', 'block');
            $('.sr__booking-form-room-booking .the-castle-inn-room').addClass('active');

            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .pennyhill-park-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .south-lodge-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .lainston-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .the-manor-house-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .royal-berkshire-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').css('display', 'none');
            $('.sr__booking-mobile-form-room-booking .fanhams-hall-room').removeClass('mobile-active');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').css('display', 'block');
            $('.sr__booking-mobile-form-room-booking .the-castle-inn-room').addClass('mobile-active');
        }
    }

    if ( tableSelected ) {
        if( tableSelected == 'pennyhill-park-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'block');
            $('.sr__booking-form-table-booking .pennyhill-park-table').addClass('active');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').removeClass('active');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').removeClass('active');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').removeClass('active');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').addClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').removeClass('mobile-active');
        } else if( tableSelected == 'south-lodge-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .pennyhill-park-table').removeClass('active');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'block');
            $('.sr__booking-form-table-booking .south-lodge-table').addClass('active');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').removeClass('active');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').removeClass('active');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').addClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').removeClass('mobile-active');
        } else if( tableSelected == 'lainston-house-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .pennyhill-park-table').removeClass('active');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').removeClass('active');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'block');
            $('.sr__booking-form-table-booking .lainston-house-table').addClass('active');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').removeClass('active');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').removeClass('active');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').addClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').removeClass('mobile-active');
        } else if( tableSelected == 'the-manor-house-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .pennyhill-park-table').removeClass('active');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').removeClass('active');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'block');
            $('.sr__booking-form-table-booking .the-manor-house-table').addClass('active');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').removeClass('active');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').removeClass('active');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').addClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').removeClass('mobile-active');
        } else if( tableSelected == 'royal-berkshire-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .pennyhill-park-table').removeClass('active');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').removeClass('active');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'block');
            $('.sr__booking-form-table-booking .royal-berkshire-table').addClass('active');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').removeClass('active');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').addClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').removeClass('mobile-active');
        } else if( tableSelected == 'fanhams-hall-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .pennyhill-park-table').removeClass('active');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').removeClass('active');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').removeClass('active');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'block');
            $('.sr__booking-form-table-booking .fanhams-hall-table').addClass('active');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').removeClass('active');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').addClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').removeClass('mobile-active');
        } else if( tableSelected == 'the-castle-inn-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .pennyhill-park-table').removeClass('active');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').removeClass('active');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').removeClass('active');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').removeClass('active');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').removeClass('active');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'block');
            $('.sr__booking-form-table-booking .the-castle-inn-table').addClass('active');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').removeClass('mobile-active');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').addClass('mobile-active');
        }
    }

    if ( spaSelected ) {
        if ( spaSelected == 'the-spa-at-pennyhill-park-treatment') {
            $('.sr__booking .pennyhill-park-spa').css('display', 'block');
            $('.sr__booking .south-lodge-spa').css('display', 'none');

            $('.sr__booking-mobile .pennyhill-park-spa').css('display', 'block');
            $('.sr__booking-mobile .south-lodge-spa').css('display', 'none');
            console.log('selected pennyhill');
        } else {
            $('.sr__booking .pennyhill-park-spa').css('display', 'none');
            $('.sr__booking .south-lodge-spa').css('display', 'block');

            $('.sr__booking-mobile .pennyhill-park-spa').css('display', 'none');
            $('.sr__booking-mobile .south-lodge-spa').css('display', 'block');
            console.log('selected south lodge');
        }
    }



    // Link the rooms booking widget to the correct property
    $('.sr__booking-form-room-booking form').attr('action', $('.sr__booking-form-property .room-select select').val());
    $('.sr__booking-mobile-form-room-booking form').attr('action', $('.sr__booking-mobile-form-property .room-select select').val());

    $('.sr__booking-form-property .room-select select, .sr__booking-mobile-form-property .room-select select').change(function() {
        $('.sr__booking-form-room-booking form, .sr__booking-mobile-form-room-booking form').attr('action', $(this).val());

        initBookingBar();
    })

    $('.sr__booking-form-property .table-select select, .sr__booking-mobile-form-property .table-select select').change(function() {
        if( $(this).val().toLowerCase() == 'pennyhill-park-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'block');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'south-lodge-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'block');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'lainston-house-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'block');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'the-manor-house-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'block');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'royal-berkshire-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'block');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'fanhams-hall-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'block');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'none');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'block');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'the-castle-inn-table' ) {
            $('.sr__booking-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-form-table-booking .the-castle-inn-table').css('display', 'block');

            $('.sr__booking-mobile-form-table-booking .pennyhill-park-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .south-lodge-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .lainston-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-manor-house-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .royal-berkshire-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .fanhams-hall-table').css('display', 'none');
            $('.sr__booking-mobile-form-table-booking .the-castle-inn-table').css('display', 'block');
        }
    })

    $('.sr__booking-form-property .tea-select select, .sr__booking-mobile-form-property .tea-select select').change(function() {
        if( $(this).val().toLowerCase() == 'pennyhill-park-tea' ) {
            $('.sr__booking-form-tea-booking .pennyhill-park-tea').css('display', 'block');
            $('.sr__booking-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .fanhams-hall-tea').css('display', 'none');

            $('.sr__booking-mobile-form-tea-booking .pennyhill-park-tea').css('display', 'block');
            $('.sr__booking-mobile-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .fanhams-hall-tea').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'south-lodge-tea' ) {
            $('.sr__booking-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .south-lodge-tea').css('display', 'block');
            $('.sr__booking-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .fanhams-hall-tea').css('display', 'none');

            $('.sr__booking-mobile-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .south-lodge-tea').css('display', 'block');
            $('.sr__booking-mobile-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .fanhams-hall-tea').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'lainston-house-tea' ) {
            $('.sr__booking-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .lainston-house-tea').css('display', 'block');
            $('.sr__booking-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .fanhams-hall-tea').css('display', 'none');

            $('.sr__booking-mobile-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .lainston-house-tea').css('display', 'block');
            $('.sr__booking-mobile-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .fanhams-hall-tea').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'the-manor-house-tea' ) {
            $('.sr__booking-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .the-manor-house-tea').css('display', 'block');
            $('.sr__booking-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .fanhams-hall-tea').css('display', 'none');

            $('.sr__booking-mobile-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .the-manor-house-tea').css('display', 'block');
            $('.sr__booking-mobile-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .fanhams-hall-tea').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'royal-berkshire-tea' ) {
            $('.sr__booking-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .royal-berkshire-tea').css('display', 'block');
            $('.sr__booking-form-tea-booking .fanhams-hall-tea').css('display', 'none');

            $('.sr__booking-mobile-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .royal-berkshire-tea').css('display', 'block');
            $('.sr__booking-mobile-form-tea-booking .fanhams-hall-tea').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'fanhams-hall-tea' ) {
            $('.sr__booking-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-form-tea-booking .fanhams-hall-tea').css('display', 'block');

            $('.sr__booking-mobile-form-tea-booking .pennyhill-park-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .south-lodge-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .lainston-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .the-manor-house-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .royal-berkshire-tea').css('display', 'none');
            $('.sr__booking-mobile-form-tea-booking .fanhams-hall-tea').css('display', 'block');
        }
    })

    $('.sr__booking-form-property .lunch-select select, .sr__booking-mobile-form-property .lunch-select select').change(function() {
        if( $(this).val().toLowerCase() == 'the-manor-house-lunch' ) {
            $('.sr__booking-form-lunch-booking .the-manor-house-lunch').css('display', 'block');
            $('.sr__booking-form-lunch-booking .fanhams-hall-lunch').css('display', 'none');

            $('.sr__booking-mobile-form-lunch-booking .the-manor-house-lunch').css('display', 'block');
            $('.sr__booking-mobile-form-lunch-booking .fanhams-hall-lunch').css('display', 'none');
        } else if( $(this).val().toLowerCase() == 'fanhams-hall-lunch' ) {
            $('.sr__booking-form-lunch-booking .the-manor-house-lunch').css('display', 'none');
            $('.sr__booking-form-lunch-booking .fanhams-hall-lunch').css('display', 'block');

            $('.sr__booking-mobile-form-lunch-booking .the-manor-house-lunch').css('display', 'none');
            $('.sr__booking-mobile-form-lunch-booking .fanhams-hall-lunch').css('display', 'block');
        }
    })

    $('.sr__booking-form-property .treatment-select select, .sr__booking-mobile-form-property .treatment-select select').change(function() {
        if ( $(this).val().toLowerCase() == 'the-spa-at-pennyhill-park-treatment' ) {
            $('.sr__booking .pennyhill-park-spa').css('display', 'block');
            $('.sr__booking .south-lodge-spa').css('display', 'none');

            $('.sr__booking-mobile .pennyhill-park-spa').css('display', 'block');
            $('.sr__booking-mobile .south-lodge-spa').css('display', 'none');
            console.log('change pennyhill');
        } else {
            $('.sr__booking .pennyhill-park-spa').css('display', 'none');
            $('.sr__booking .south-lodge-spa').css('display', 'block');

            $('.sr__booking-mobile .pennyhill-park-spa').css('display', 'none');
            $('.sr__booking-mobile .south-lodge-spa').css('display', 'block');
            console.log('change south lodge');
        }
    })

    $('.primary-nav-items >ul >li').mouseenter(function() {
        if( $(this).has('.primary-nav-sub') ) {
            $(this).find('.primary-nav-sub').addClass('open');
        }
    })

    $('.primary-nav-items >ul >li').mouseleave(function() {
        if( $(this).has('.primary-nav-sub') ) {
            $(this).find('.primary-nav-sub').removeClass('open');
        }
    })

    $('.nav-wrap > ul > li > a.primary-nav-has-sub').on('click', function(e) {
        $(this).toggleClass('open');

        e.preventDefault();
        return false;
    });

    if ( $(window).width() > 1000 ) {
        var maxHeightSquaresItem = 0;

        $('.sr__squares-item').each( function() {
            if ( $(this).height() > maxHeightSquaresItem ) {
                maxHeightSquaresItem = $(this).height();
            }
        });

        $('.sr__squares-item').height(maxHeightSquaresItem);


        var maxHeightSquaresText = 0;

        $('.sr__squares-item-split-text').each( function() {
            if ( $(this).height() > maxHeightSquaresText ) {
                maxHeightSquaresText = $(this).height();
            }
        });

        $('.sr__squares-item-split-text').height(maxHeightSquaresText);
    }

    var maxHeightOffersText = 0;

	$('.sr__offers-landing-grid-double-item-info-wrap p').each( function() {
		if ( $(this).height() > maxHeightOffersText ) {
			maxHeightOffersText = $(this).height();
		}
	});

	$('.sr__offers-landing-grid-double-item-info-wrap p').height(maxHeightOffersText);

});

// init bookingbar date selection
function initBookingBar() {
    // Reset the dropdowns to clear autocomplete data for various browser issues
    $('.sr__booking option:selected').each(function() {
        $(this).prop('selected', true);
    });

    if ( $(window).width() >= 767 ) {
        var bookingContainer = $('.sr__booking-form-room-booking form');
    } else {
        var bookingContainer = $('.sr__booking-mobile-form-room-booking form');
    }

    var nowTemp = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(nowTemp.getDate() + 1);

    var minDate = new Date(2021, 4, 17);
    if ( minDate > nowTemp ) {
        nowTemp = minDate;
        tomorrow.setDate(nowTemp.getDate() + 1);
    }

    var $checkInField = bookingContainer.find('.check-in');
    var $checkOutField = bookingContainer.find('.check-out');

    $checkInField.on('hide.datepicker', function () {
        var checkInDate  = $checkInField.datepicker('getDate');
        var checkOutDate = $checkOutField.datepicker('getDate');
        var dayAfterCheckIn = new Date(checkInDate);
        dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

        /*var nightsNum = (checkOutDate - checkInDate) / (1000*60*60*24); //get number of nights - comes in as miliseconds
        var nightStr = nightsNum.toString();
        var nights = bookingContainer.find('.nights');

        nights.value = nightStr;*/

        // Ensure checkout comes after checkin.
        // Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
        if ( checkInDate >= checkOutDate ) {
            $checkOutField.datepicker('setDate', dayAfterCheckIn);
        }

        $checkOutField.datepicker('setStartDate', dayAfterCheckIn);

        $checkOutField[0].focus();
    });

    $checkOutField.on('hide.datepicker', function() {
        var checkInDate  = $checkInField.datepicker('getDate');
        var checkOutDate = $checkOutField.datepicker('getDate');

        /*var nightsNum = (checkOutDate - checkInDate) / (1000*60*60*24); //get number of nights - comes in as miliseconds
        var nightStr = nightsNum.toString();
        var nights = bookingContainer.find('.nights');
        nights.attr('data-nights', nightStr);*/

        var arrival =bookingContainer.find('.check-in');
        arrival.attr('data-date' ,$checkInField.val());

    })

    var checkin = $checkInField.datepicker({
        format: 'dd/mm/yyyy',
        //autoPick: true,
        autoHide: true,
        date: null,
        startDate: nowTemp
    });

    var checkout = $checkOutField.datepicker({
        format: 'dd/mm/yyyy',
        //autoPick: true,
        autoHide: true,
        date: null,
        startDate: tomorrow
    });

    bookingContainer.on('submit', function () {
        // Calculate the number of nights between the 2 dates
        if (checkin.val() == '') {
            checkin.val( pad( nowTemp.getDate() , 2) + '/' + pad( nowTemp.getMonth() + 1*1 , 2) + '/' + nowTemp.getFullYear() );
        }

        if (checkout.val() == '') {
            checkout.val( pad( tomorrow.getDate() , 2) + '/' + pad( tomorrow.getMonth() + 1*1 , 2) + '/' + tomorrow.getFullYear() );
        }

        var checkInDate  = $checkInField.datepicker('getDate');
        var checkOutDate = $checkOutField.datepicker('getDate');

        var nightsNum = (checkOutDate - checkInDate) / (1000*60*60*24); //get number of nights - comes in as miliseconds
        var nightStr = nightsNum.toString();
        bookingContainer.find('.nights').val(nightStr);

    });

}

function changeBookingProperty() {
    $('.sr__booking-wrap .room-select select, .sr__booking-mobile-wrap .room-select select').change(function() {
        setTimeout(function() {
        initBookingBar()
        },250)
    })

}


function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
