$(function () {

    if ($('.myexclusive, .profilecenter').length == 0) return false;

    var $profilecenter = $('.profilecenter section, .myexclusive section');
    var $tabsContainer = $profilecenter.find('.myexclusive-tabs');
    var $contents = $profilecenter.find('.contents');

    $profilecenter.tabbed({
        tabs: $tabsContainer,
        contents: $contents,
        initialTab: $tabsContainer.find('li').length - 1,
        afterChange: function () {

        }
    });

    /* Apply height of tallest element to all sp layout doesn't break */

    //var MESavedHeights = $('.myexclusive-saved .grid-text').map(function () {
    //    return $(this).height();
    //}).get();

    //var maxHeight = Math.max.apply(null, MESavedHeights);

    //$('.myexclusive-saved .grid-text').height(maxHeight);
  
 
    $('.myexclusive-saved .me-list').equalColumns({
        breakpoints: {
            0: 4,
            768: 2,
            1281: 4
        }
    });
    

    /* Remove from ME Like list */

    var $removeFromME = $('.remove').parent('form');

    $removeFromME.on('submit', function (e) {

        // Prevent submitting
        e.preventDefault();

        // Form data
        var form = $(this);
        var post_url = form.attr('action');
        var post_data = form.serialize();

        $.ajax({
            type: 'POST',
            url: post_url,
            data: post_data,
            dataType: 'json',
            success: function (data) {
                form.closest('.grid-text').fadeOut();
            },
            error: function () {

            }
        });
    });

    /* If message is displayed fade out after few seconds */

    setTimeout(function () {
        $('.profilecenter .message').slideUp(500)
    }, 4000);


 

});