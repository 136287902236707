$(function () {
    if ($('.journallisting').length <= 0) return false;

    var property = getParameterByName('property');
    var category = getParameterByName('category');
    

    $('#journal-filter-button').on('click', function () {

        property = $('.journal-filter select[name=propertyList]').val()
        category = $('.journal-filter select[name=categories]').val()

        updateParameter('property', property);
        updateParameter('category', category);

        filterJournals(property, category);
    });
    
    // Set the dropdown values to the querystring values.
    setDropdownValues(property, category);

    // Load journals when page loads.
    filterJournals(property, category);



    // FUNCTIONS

    function setDropdownValues(property, category) {
        if (property != null && property != undefined) {
            $('.journal-filter select[id=propertyList]').val(property);
            $('.journal-filter select[id=propertyList] option').removeClass('selected');
            $('.journal-filter select[id=propertyList] option[value=' + property + ']').addClass('selected');
            $('.property-dropdown .cab-select-box').text($('.property-dropdown li[rel=' + property + ']').text())
        }
        if (category != null && category != undefined) {
            $('.journal-filter select[id=categories]').val(category);
            $('.journal-filter select[id=categories] option').removeClass('selected');
            $('.journal-filter select[id=categories] option[value=' + category + ']').addClass('selected');
            $('.category-dropdown .cab-select-box').text($('.category-dropdown li[rel=' + category + ']').text())
        }
    };

    function filterJournals(property, category) {
        getFilteredJournals(property, category, function (data) {

            if (data != null && data.Journals.length > 0) {
                var template = Handlebars.compile($('#JournalListingTemplate').html());
            }
            else {
                var template = Handlebars.compile($('#JournalsUnavailableTemplate').html());
                console.log('INFO: Filtering failed - ' + data);
            }

            var html = template(data);
            $('#journals-list').html(html);
        });
    };

    function getFilteredJournals(property, category, callback) {
        var params = {
            Property: property,
            Category: category
        };

        $.ajax({
            type: "GET",
            datatype: "application/json",
            data: params,
            url: "/umbraco/api/journals/filterjournals",
            success: function (data) {
                callback(data);
            },
            error: function (request, status, error) {
                console.log("ERROR: [/api/journals/filterjournals] Unable to filter journals - " + error);
                callback(null);
            }
        });
    };

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    function updateParameter(key, value) {
        if (history.pushState) {
            var newurl = '';
            var uri = window.location.href;
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                if (value == null || value == undefined || value == "") {
                    if (uri.indexOf('&') > -1) {
                        newurl = uri.replace(new RegExp("[&]?" + key + "=.*?(&|$)", "i"), '');
                    }
                    else {
                        newurl = uri.replace(re, '');
                    }
                }
                else {
                    newurl = uri.replace(re, '$1' + key + "=" + value + '$2');
                }
            }
            else if (value != null && value != undefined && value != "") {
                newurl = uri + separator + key + "=" + value;
            }
            window.history.pushState({ path: newurl }, '', newurl);
        }
    };

    Handlebars.registerHelper('coalesce', function (a, b) { return a || b; });





});
