$(function () {

    if ($('.offeritem, .eventitem').length == 0) return false;

    /* Offer Map */
    var map,
    bounds = null
    markers = [],
    ib = null;

    /* Init Map */
    var initMap = function () {
        map = new google.maps.Map(document.getElementById('OfferMap'), {
            zoom: 9,
            center: { lat: 51.507302, lng: -0.127429 },
            disableDefaultUI: true,
            scrollwheel: false,
            draggable: (window.matchMedia('(max-width: 1000px)').matches ? false : true)
        });

        bounds = new google.maps.LatLngBounds();
        addMarkers($('#BookDetailsContainer > div'));
        map.fitBounds(bounds);
    }();

    /* Add Markers Based on Visible Data Attributes */
    function addMarkers($container) {
        $container.find('[data-lat]').each(function () {
            var position = { lat: parseFloat($(this).attr('data-lat')), lng: parseFloat($(this).attr('data-lng')) };
            var marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: new google.maps.MarkerImage($(this).attr('data-marker'), null, null, null, new google.maps.Size(37, 46)),
                pos: position
            });
            markers.push(marker);
            bounds.union(new google.maps.LatLngBounds(new google.maps.LatLng(position.lat, position.lng)));
        });
    }
        
    $('.book-offer .cab-select-box').text('Please select');

    $('#BookDetailsContainer').children('div').hover(function () {
        var figure = $(this).find('figure');

        var HotelName = $(figure).children('img').attr('alt');
        var HotelData = $(figure).siblings('.hotel-data').clone();
        var position = { lat: parseFloat($(figure).attr('data-lat')), lng: parseFloat($(figure).attr('data-lng')) };
        var bookLink = $(figure).parent().find('a').attr('href');
        var btnTitle = $(figure).parent().find('a').html();

        map.setCenter(position);
        map.setZoom(13);

        $('#BookDetailsContainer figure').each(function () {
            $(figure).removeClass('active');
        });
        $(figure).addClass('active');

        $('.book-offer').find('.cab-select-box').text(HotelName);
        $('.book-offer').find('.cab-select-hidden').val(HotelName);
        $('.map-flyout').html(HotelData);
        $('.map-flyout, #OfferMap').addClass('active');
        $('#BookDetailsContainer a.offer-hotel-booklink').addClass('active').attr('href', bookLink).html(btnTitle);
    });
});